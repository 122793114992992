body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.pointer {
  cursor: pointer;
}
body {
    margin: 0;
    font-family: 'Cormorant Garamond', serif;
    FONT-SIZE: 16px;
    LINE-HEIGHT: 22.4px;
    color: #ffffff, opacity 80.0%;
    text-align: left;
    background-color: #000;
}
h1 {
    font-size: 2.5rem;
    font-family: 'Cormorant Garamond', serif;
    font-size: 52px;
    line-height: 52px;
    color: #ffffff;
    text-align: center;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
.App_version__17zth {
    font-family: 'Cormorant Garamond', serif;
    font-size: 20px;
    line-height: 28px;
}
.btn-dark {
    color: #fff;
    background-color: #c4171d;
    color: ##ffffff;
    font-size:12px;
    line-height:16.8px;
    box-shadow: rgb(0, 0, 0) 0px 1px 21px 5px;
    border-color: #343a40;
    font-family: 'gill-sans-nova', Arial, sans-serif;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color:#0d0b0f;
    color: #fff;
    font-family: 'Cormorant Garamond', serif;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    height: auto;
    border: 1px solid white;
    border-image:url(doily.png) 40;
}
